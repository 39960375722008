import React from 'react'
import { Html } from '@react-three/drei'
import { useStore } from '../state'
import { Poi } from '../services/projectService'
import styled from 'styled-components'
import plusIcon from '../assets/images/plus.svg'

export type PoiMarkerProps = {
  poi: Poi
}

const Container = styled.div`
  padding: 4px 6px 0px 4px;
  border-style: solid;
  border-width: 1px;
  border-radius: 20px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 15px;
  height: 34px;
  text-align: left;
  user-select: none;

  :hover {
    background-color: rgba(247, 245, 252, 0.9);
    cursor: pointer;
  }
`

const Marker = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: rgba(61, 73, 204, 0.7);
  text-align: center;
`

const Plus = styled.img`
  width: 20px;
  margin-top: 5px;
`

const Name = styled.div`
  display: inline-block;
  margin-right: 9px;
  margin-left: 39px;
  font-size: 16px;
  line-height: 31px;
`

export function PoiMarker({ poi }: PoiMarkerProps) {
  const selectPoi = useStore((state) => state.api.selectPoi)
  const selectedPoiId = useStore((state) => state.selectedPoiId)
  const { poiDescriptionOn, togglePoiDescription } = useStore(
    (state) => state.api
  )

  const isSelected = selectedPoiId === poi.id
  const backgroundColor = isSelected ? '#794dff' : 'rgba(247, 245, 252, 0.6)'
  const borderColor = isSelected ? '#794dff' : '#c7c6cc'
  const color = isSelected ? 'rgb(247, 245, 252)' : '#232c85'
  const boxShadow = isSelected ? '1px 1px 6px 0 rgba(51, 51, 51, 0.5)' : 'none'

  const markerPosition = {
    x: poi.markerPosition.x,
    y: poi.markerPosition.height,
    z: poi.markerPosition.y,
  }

  const selectThisPoi = () => {
    if (isSelected) {
      togglePoiDescription()
    } else {
      poiDescriptionOn()
    }
    selectPoi(poi.id)
  }

  return (
    <mesh position={[markerPosition.x, markerPosition.y, markerPosition.z]}>
      <Html>
        <Container 
          onClick={selectThisPoi} 
          style={{  backgroundColor: backgroundColor, borderColor: borderColor, color: color, boxShadow: boxShadow, width: '190px' }}>
          <Marker>
            <Plus src={plusIcon} alt={'+'} />
          </Marker>
          <Name>{poi.name}</Name>
        </Container>
      </Html>
    </mesh>
  )
}
