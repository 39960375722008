import { useState, useEffect } from 'react'
import { getPois, Poi } from '../services/projectService'
import { useStore } from '../state'

export function usePointOfInterests(): Poi[] {
  const [pois, setPois] = useState([] as Poi[])

  useEffect(() => {
    const fetchPoiData = async () => {
      const poiData = await getPois()
      setPois(poiData)
    }
    fetchPoiData()
  }, [])

  return pois
}

export function useSelectedPointOfInterest(): Poi | undefined {
  const pois = usePointOfInterests()
  const selectedPoiId = useStore((state) => state.selectedPoiId)

  return pois.find((poi) => poi.id === selectedPoiId)
}
