import React from 'react'

import './App.css'
import { ProjectSceneView } from './components/ProjectSceneView'

function App() {
  return (
    <div className="App">
      <ProjectSceneView />
    </div>
  )
}

export default App
