import { Suspense, useMemo } from 'react'
import { Canvas, useFrame, useThree } from '@react-three/fiber'
import { Stats } from '@react-three/drei'
import CameraControls from 'camera-controls'
import * as THREE from 'three'
import useWindowDimensions from '../hooks/useWindowDimensions'
import {
  usePointOfInterests,
  useSelectedPointOfInterest,
} from '../hooks/usePointOfInterests'
import { Model } from './Model'
import { PoiMarker } from './PoiMarker'
import Lights from './Lights'
import { useProjectInformation } from '../hooks/useProjectInformation'
import { getAssetPath } from '../assets'

CameraControls.install({ THREE })

const initialCameraPosition = { x: 0, y: 1000, z: -1000 }
const initialCameraTarget = { x: 0, y: 0, height: 0 }

export function Scene() {
  const { height: windowHeight } = useWindowDimensions()
  const pois = usePointOfInterests()
  const selectedPoi = useSelectedPointOfInterest()

  // TODO: We should probably solve this using promises and useEffect instead...
  const projectInformation = useProjectInformation()
  const modelPath = projectInformation
    ? getAssetPath(projectInformation.model)
    : ''

  function Controls() {
    const { camera, gl } = useThree()
    const controls = useMemo(
      () => new CameraControls(camera, gl.domElement),
      [camera]
    )
    const cameraPosition = selectedPoi?.cameraPosition ?? initialCameraPosition
    const target = selectedPoi?.markerPosition ?? initialCameraTarget
    controls.setLookAt(
      cameraPosition.x,
      cameraPosition.y,
      cameraPosition.z,
      target.x,
      target.height,
      target.y,
      true
    )
    return useFrame((state, delta) => controls.update(delta))
  }

  return (
    <Canvas
      style={{ height: windowHeight, zIndex: 0 }}
      shadows
      camera={{
        fov: 45,
        near: 0.1,
        far: 5000,
        zoom: 1,
        // Position kind of randomly selected to get a niceish zoom-in effect when loading the scene
        position: [500, 500, 500],
      }}
    >
      <Lights showLightHelpers />
      {pois.map((poi) => (
        <PoiMarker key={poi.id} poi={poi} />
      ))}
      {/* TODO: provide a proper loading Suspense fallback */}
      {modelPath ? (
        <Suspense fallback={null}>
          <Model src={modelPath} />
        </Suspense>
      ) : null}
      <Controls />
      <Stats className={'stats'} />
      {/* <axesHelper args={[100]} /> */}
    </Canvas>
  )
}
