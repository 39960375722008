import React from 'react'
import styled from 'styled-components'
import {
  usePointOfInterests,
  useSelectedPointOfInterest,
} from '../hooks/usePointOfInterests'
import { useStore } from '../state'

export function PoiMenu() {
  const { togglePoiDescription } = useStore((state) => state.api)
  const selectedPoi = useSelectedPointOfInterest()

  function ActivePoi() {
    return (
      <>
        <ActivePoiDescription onClick={togglePoiDescription}>
          {selectedPoi?.name}
        </ActivePoiDescription>
      </>
    )
  }

  function PoiCarousel() {
    const pois = usePointOfInterests()
    const selectPoi = useStore((state) => state.api.selectPoi)
    const selectedPoiId = useStore((state) => state.selectedPoiId)

    function selectPreviousPoi() {
      const currentPoiIndex = pois.findIndex((poi) => poi.id === selectedPoiId)
      const lastPoiIndex = pois.length - 1
      const previousPoiIndex =
        currentPoiIndex <= 0 ? lastPoiIndex : currentPoiIndex - 1
      selectPoi(pois[previousPoiIndex].id)
    }

    function selectNextPoi() {
      const currentPoiIndex = pois.findIndex((poi) => poi.id === selectedPoiId)
      const lastPoiIndex = pois.length - 1
      const firstPoiIndex = 0
      const nextPoiIndex =
        currentPoiIndex >= lastPoiIndex ? firstPoiIndex : currentPoiIndex + 1
      selectPoi(pois[nextPoiIndex].id)
    }

    return (
        <PoiNavigationBar>
          <PreviousButton onClick={selectPreviousPoi}>Previous</PreviousButton>
          <NextButton onClick={selectNextPoi}>Next</NextButton>
        </PoiNavigationBar>
      )
  }

  return (
    <PoiCarousel />
  )
}

const PoiNavigationBar = styled.div`
  padding: 15px 15px 17px;
  line-height: 24px;
  font-weight: 500;
  text-decoration: none;
  border-radius: 0 0 12px 12px;
  background-color: #e6e6e6;
`

const ActivePoiDescription = styled.p`
  padding-right: 10px;
  cursor: pointer;
  user-select: none;
`

const PreviousButton = styled.button`
  padding: 0;
  font-family: 'IBM Plex Sans', sans-serif;
  color: #666;
  font-size: 16px;
  background: transparent;
  border: none;
  user-select: none;

  :hover {
    cursor: pointer;
    color: #232c85;
  }
`

const NextButton = styled.button`
  padding: 0;
  float: right;
  font-family: 'IBM Plex Sans', sans-serif;
  color: #666;
  font-size: 16px;
  background: transparent;
  border: none;
  user-select: none;

  :hover {
    cursor: pointer;
    color: #232c85;
  }
`
