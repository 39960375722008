import { getProjectPublicDirectoryPath } from '../assets'

export type Project = {
  id: string
  model: string
  name: string
  description: string
  pois: Poi[]
}

export type Poi = {
  id: number
  name: string
  location: string
  description: string
  notes?: Note[]
  markerPosition: {
    x: number
    y: number
    height: number
  }
  cameraPosition: {
    x: number
    y: number
    z: number
  }
}

export type Note = {
  title: string
  body: string
  images?: string[]
}

export async function getProjectInformation(): Promise<Project> {
  const path = `${getProjectPublicDirectoryPath()}project-data.json`
  const response = await fetch(path)
  const projectInfo = await response.json()
  return projectInfo
}

export async function getPois(): Promise<Poi[]> {
  const projectInformation = await getProjectInformation()
  return projectInformation.pois as Poi[]
}
