import create from 'zustand'

type State = {
  selectedPoiId: number
  showPoiDescription: boolean

  api: {
    selectPoi: (withId: number) => void
    togglePoiDescription: () => void
    poiDescriptionOn: () => void
    poiDescriptionOff: () => void
  }
}

export const useStore = create<State>((set) => ({
  selectedPoiId: 0,
  showPoiDescription: false,

  api: {
    selectPoi: (withId) => set((state) => ({ selectedPoiId: withId })),
    togglePoiDescription: () =>
      set((state) => ({ showPoiDescription: !state.showPoiDescription })),
    poiDescriptionOn: () => set((state) => ({ showPoiDescription: true })),
    poiDescriptionOff: () => set((state) => ({ showPoiDescription: false })),
  },
}))
