import { useGLTF } from '@react-three/drei'
import { Mesh, Object3D } from 'three'

export type ModelProps = {
  src: string
}

export function Model({ src }: ModelProps) {
  const gltf = useGLTF(src, true, true)

  gltf.scene.traverse(function (node: Object3D) {
    if (node instanceof Mesh) {
      node.castShadow = true
      node.receiveShadow = true
    }
  })

  return <primitive object={gltf.scene} dispose={null} />
}
