import React from 'react'
import styled from 'styled-components'
import { useSelectedPointOfInterest } from '../hooks/usePointOfInterests'
import { useStore } from '../state'
import closeIcon from '../assets/images/close.svg'
import { PoiNote } from './PoiNote'
import { PoiMenu } from './PoiMenu'

const PoiDescriptionContainer = styled.div`
  position: absolute;
  top: 0%;
  right: 0%;
  width: 450px;
  height: 97%;
  margin: 12px 12px 12px 0;
  border-style: solid;
  border-width: 1px;
  border-color: #c7c6cc;
  border-radius: 12px;
  background-color: rgba(247, 245, 252, 0.9);
  text-align: left;

  @media screen and (max-width: 479px) {
    width: 94%;
    height: 96%;
  }
`

const PoiContent = styled.div`
  height: 84%;
  padding: 0 12px 12px;
  border-style: none none solid;
  border-width: 1px;
  border-color: #000 #000 #c7c6cc;
  overflow-y: auto;
  overflow-x: hidden;

  @media screen and (max-width: 479px) {
    height: 75.85%;
  }

  @media screen and (min-width: 479px) and (max-width: 1400px) {
    height: 82.4%;
  }
`

const PoiHeader  = styled.div`
`

const PoiName = styled.p`
  font-weight: 600;
  font-size: 24px;
  line-height: 27px;
  margin: 12px;
  color: #232c85;
  width: 90%;
`

const CloseButton = styled.button`
  position: absolute;
  top: 6px;
  right: 12px;
  width: 37px;
  height: 37px;
  margin-top: 3px;
  border-style: solid;
  border-width: 1px;
  border-color: #c7c6cc;
  border-radius: 20px;
  background-color: #ccc;
  font-family: 'IBM Plex Sans', sans-serif;
  color: #414141;
  padding: 3px 6px;

  :hover  {
    background-color: rgba(61, 73, 204, 0.7);
    cursor: pointer;
  }
`

const CloseButtonImage = styled.img`
  width: 24px;
  margin-top: 3px;
`

const PoiLocation = styled.p`
  font-size: 15px;
  margin: 12px;
  color: #9d9ea8;
`

const PoiText = styled.p`
  font-size: 18px;
  line-height: 24px;
  color: #9d9ea8;
`

export function PoiDescription() {
  const selectedPoi = useSelectedPointOfInterest()
  const showDescription = useStore((state) => state.showPoiDescription)
  const hidePoiDescription = useStore((state) => state.api.poiDescriptionOff)

  if (showDescription) {
    return (
      <PoiDescriptionContainer>
        <PoiHeader>
          <PoiName>{selectedPoi?.name}</PoiName>
          <CloseButton>
            <CloseButtonImage
              onClick={hidePoiDescription}
              src={closeIcon}
              alt="Close"
            />
          </CloseButton>
          <PoiLocation>{selectedPoi?.location}</PoiLocation>
        </PoiHeader>
        <PoiContent>
          <PoiText>{selectedPoi?.description}</PoiText>
          {selectedPoi?.notes
            ? selectedPoi?.notes.map((note, index) => (
                <PoiNote key={`${selectedPoi.id}-${index}`} note={note} />
              ))
            : null}
        </PoiContent>
        <PoiMenu />
      </PoiDescriptionContainer>
    )
  } else {
    return null
  }
}
