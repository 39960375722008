import React, { useState } from 'react'
import styled from 'styled-components'
import infoOutlineIcon from '../assets/images/info-outline.svg'
import infoFilledIcon from '../assets/images/info-filled.svg'
import playIcon from '../assets/images/play.svg'
import { useProjectInformation } from '../hooks/useProjectInformation'

const TitleContainer = styled.div`
  position: absolute;
  top: 12px;
  left: 12px;
  padding: 9px;
  border-style: solid;
  border-width: 1px;
  border-color: #c7c6cc;
  border-radius: 12px;
  background-color: rgba(247, 245, 252, 0.6);

  :hover {
    cursor: pointer;
    background-color: rgba(247, 245, 252, 0.75);
  }
`

const Title = styled.span`
  font-size: 18pt;
  line-height: 25px;
  font-weight: 600;
  margin-right: 24px;
  float: left;
  color: #232c85;
`

const Description = styled.div`
  position: absolute;
  text-align: left;
  max-width: 450px;
  margin-right: auto;
  margin-left: auto;
  border-style: solid;
  border-width: 1px;
  border-color: #c7c6cc;
  border-radius: 12px;
  background-color: rgba(247, 245, 252, 0.75);
  top: 120px;

  @media screen and (max-width: 479px) {
    width: 94%;
    overflow-y: auto;
    top: 12px;
  }
`

const DescriptionHeader = styled.div`
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 15px 12px 12px;
  border-radius: 12px 12px 0 0;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 15pt;
  font-weight: 500;
  color: #9d9ea8;
  background-color: rgba(247, 245, 252, 0.9);
`

const DescriptionFooter = styled.div`
  padding: 15px 15px 21px;
  font-family: 'IBM Plex Sans', sans-serif;
  color: #666;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  text-decoration: none;
`

const Credits = styled.div`
  margin-top: 12px;
  color: #666;
`

const InfoImageButton = styled.img`
  height: 25px;
`

const Explore = styled.button`
  width: 100%;
  border-style: solid;
  border-width: 1px;
  border-color: #c7c6cc;
  border-radius: 20px;
  background-color: rgba(225, 223, 230, 0.8);
  color: #232c85;
  font-size: 18px;
  line-height: 21px;
  font-weight: 400;
  text-align: center;
  padding: 9px 15px;
  margin-top: 15px;
  font-family: 'IBM Plex Sans', sans-serif;

  :hover {
    color: #f7f5fc;
    background-color: #794dff;
    cursor: pointer;
    box-shadow: 1px 1px 6px 0 rgba(51, 51, 51, 0.5);
    border-color: #794dff;
  }
`

const Play = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
  margin-top: -25px;
  margin-left: -9px;
  border-radius: 20px;
  background-color: #232c85;
  box-shadow: 1px 1px 6px 0 rgba(51, 51, 51, 0.5);
`

const PlayIcon = styled.img`
  width: 12px;
  margin-top: 7px;
  margin-left: 3px;
`

export function ProjectDescription() {
  const projectInformation = useProjectInformation()

  const [showDescription, setShowDescription] = useState(true)

  const toggleDescription = () => {
    setShowDescription(!showDescription)
  }

  return (
    <>
      <TitleContainer
        onClick={toggleDescription}
        style={{ display: showDescription ? 'none' : 'block' }}
      >
        <Title>{projectInformation?.name}</Title>
        <InfoImageButton
          src={showDescription ? infoFilledIcon : infoOutlineIcon}
          alt="info"
        />
      </TitleContainer>
      <Description style={{ display: showDescription ? 'block' : 'none' }}>
        <DescriptionHeader>
          <Title
            style={{ width: '100%', color: '#232c85', margin: '0 0 15px 0' }}
          >
            {projectInformation?.name}
          </Title>
          {projectInformation?.description}
          <Explore onClick={toggleDescription}>
            Explore
            <Play>
              <PlayIcon src={playIcon}></PlayIcon>
            </Play>
          </Explore>
        </DescriptionHeader>
        <DescriptionFooter>
          <Credits>Campaign leader: Claiborne Avenue Alliance</Credits>
          <Credits>
            Interactive experience developed by: Collective Form
          </Credits>
        </DescriptionFooter>
      </Description>
    </>
  )
}
