import { useState, useEffect } from 'react'
import { getProjectInformation, Project } from '../services/projectService'

export function useProjectInformation(): Project | undefined {
  const [projectInformation, setProjectInformation] = useState<Project>()

  useEffect(() => {
    const fetchProjectInfo = async () => {
      const projectInformation = await getProjectInformation()
      setProjectInformation(projectInformation)
    }
    fetchProjectInfo()
  }, [])

  return projectInformation
}
